(function () {
    'use strict';

    angular
        .module('app.constant')
        .constant('WebApiUrl',
            'https://connect-dev-ws.dca.ca.gov/cba/api/'
            // 'https://alfsbtestws.inlumon.com/api/'
            // 'https://cadcabpelsgws.inlumon.com/api/'
            // 'http://localhost:8082/api/'
            //'http://localhost:8096//api/'
            // 'https://connect-dev-ws.bpelsg.ca.gov/api/'
            // 'https://connect-dev-ws.bpelsg.ca.gov/api/'

        );
})();